import React from "react"
import Layout from "../components/layout"
import Metadata from "../components/metadata"

const OurStory = () => {
  return (
    <Layout>
      <Metadata title="Our Story" description="About us and the history of Clean Beach Club of Lido." />
      <div>
        <h1>Our Story</h1>
        <p>Lorem ipsum.</p>
      </div>
    </Layout>
  )
}

export default OurStory